.App {
  min-width: 100vw;
  min-height: 100vh;
  max-height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;

  background-image: url("./Images/fone.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 10px;
}

.mainContainer {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.containerQuestions {
  display: flex;
  justify-content: center;
  margin: auto 0 80px 0;
}
.nextOrPrevConsole {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nextOrPrevConsole span {
  cursor: pointer;
  font-size: 30px;
  transition: 0.5s;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 7px;
  border: 1px solid #94c11c;
  border-radius: 50%;
}
.nextOrPrevConsole span:hover {
  color: white;
  border: 1px solid white;
}
.pagination {
  display: flex;
  gap: 5px;
}
.pagination div {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid white;
  cursor: pointer;
}
.poweredByMero {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #252525;
  margin-top: 20px;
  border-radius: 10px;
}
.finishButton {
  color: black;
  background: #94c11c;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  transition: 0.5s;
}
.finishButton:hover {
  background: white;
}
@media (max-width: 450px) {
  .poweredByMero {
    gap: 5px;
  }
  .poweredByMero span {
    font-size: 14px;
    max-width: 100px;
  }
  .poweredByMero img {
    width: 100px;
  }
  .pagination {
    gap: 3px;
  }
  .pagination div {
    width: 7px;
    height: 7px;
  }
}
