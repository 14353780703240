.head {
  width: max-content;
  margin: 0 auto 40px auto;
  max-width: 500px;
  text-align: center;
  font-size: 18px;
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.mainChildren {
  position: relative;
  padding: 25px 5px;
  max-width: 300px;
  border: 1px solid #00457c;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: 0.5s;
}
.mainChildren:hover {
  background: white;
  border: 1px solid white;
  color: black;
}
.clouse {
  position: absolute;
  top: 5%;
  right: 5%;
  transition: 0.5s;
}
.clouse:hover {
  transform: scale(1.1, 1.1);
  text-shadow: 0 0 5px white;
  color: black;
  font-weight: bold;
  transition: 0.5s;
}
.textInput {
  grid-column: 1/3;
  margin-top: 30px;
  border-bottom: 1px solid #00457c;
  padding-bottom: 10px;
  padding-left: 20px;
}
@media (max-width: 650px) {
  .main {
    grid-template-columns: 1fr;
  }
  .textInput {
    grid-column: 1;
  }
  .head {
    max-width: 250px;
  }
}
