.mainOnScale {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
}
.head {
  max-width: 600px;
  font-size: 18px;
  margin: 0 auto;
  text-align: center;
}
.textInput {
  margin-top: 15px;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #00457c;
}
