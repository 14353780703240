.main {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px 50px;
  border-radius: 10px;
}
.main h2 {
  text-align: center;
  margin-bottom: 30px;
}
.main p {
  max-width: 500px;
}
.main ul {
  margin: 10px 0 20px 20px;
}
.main button {
  border: none;
  font-size: 18px;
  padding: 5px 15px;
  background-color: #94c11c;
  color: black;
  font-weight: 700;
  border-radius: 10px;
  cursor: pointer;
}

@media (max-width:700px){
    .main p {
        max-width: 300px;
      }
}
