.gifts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: max-content;
  margin: 0 auto;
}
.giftComponentBody {
  width: 250px;
  height: 100px;
  cursor: pointer;
  transition: 0.5s;
  border-radius: 10px;
  overflow: hidden;
}
.giftComponentBody:hover {
  box-shadow: 0 0 5px #94c11c;
}
.giftComponentBody img {
  width: 100%;
  height: 100%;
}
.sendButton {
  margin-top: 30px;
  color: black;
  background: #94c11c;
  font-weight: 700;
  border: none;
  cursor: pointer;
  width: max-content;
  padding: 5px 15px;
  border-radius: 10px;
  transition: 0.5s;
}
.sendButton:hover {
  background: white;
  color: black;
}
.giftHead {
  color: white;
  font-size: 18px;
  max-width: 400px;
  margin: 0 auto 30px auto;
}
.personalDataContainer {
  width: max-content;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.personalDataContainer input {
  padding-bottom: 10px;
  border-bottom: 1px solid #00457c;
  width: 300px;
  color: white;
}
.personalDataContainer h2 {
  font-size: 16px;
  max-width: 500px;
  margin-bottom: 30px;
}
.finalH2 {
  max-width: 400px;
  text-align: center;
  font-size: 18px;
  margin: 0 auto 30px auto;
}
@media (max-width: 630px) {
  .gifts {
    grid-template-columns: 1fr;
  }
  .finalH2 {
    max-width: 250px;
  }
  .personalDataContainer h2 {
    max-width: 220px;
  }
  .giftHead {
    max-width: 250px;
  }
}
@media (max-width: 480px) {
  .personalDataContainer input {
    width: 220px;
  }
}
